import React from 'react';
import { connectAPI } from '../helpers';
import Item from '../components/Item';
import axios from 'axios';
import styled from 'styled-components';


const TestStyle = styled.div`
	display: flex;
	justify-content: start-flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 720px;
	justify-content: space-around;
	height: 1080px;
	overflow-y: scroll;
`;

if (!Object.entries)
	Object.entries = function( obj ){
	      var ownProps = Object.keys( obj ),
	         i = ownProps.length,
	         resArray = new Array(i); // preallocate the Array

	      while (i--)
	         resArray[i] = [ownProps[i], obj[ownProps[i]]];
	      return resArray;
	   };
	   
   if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);
	   
class MenuItems extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			menus: [],
			picMediaVal: ''
		};
	}

  static getDerivedStateFromProps(props, state) {
    return { menus: props.menus };
  }

	async componentDidMount() {
		if (this.state.menus) {
			for (var idsList of Object.entries(this.state.menus)) {
				await this.getItems(
					parseInt(idsList[1].idItemCustomized),
					parseInt(idsList[1].idRefUwItemMaster),
					parseInt(idsList[1].idComposition),
					parseInt(this.props.menuId)
				)

			}
		}
	}

	async getItems(itemId, masterId, compositionId, menuId) {
		// Connect to API to get menu items
		const data = connectAPI('findPublishedItems', 'ItemControllerImpl', 'item/findPublishedItems');
		data.append('remoteAddr', localStorage.getItem('visitorIpAddr'));
		data.append('sessionID', localStorage.getItem('visitorSession'));
		data.append('previousExternalID','XXX');
		data.append('userCallerType','CallerTType.UWEAT_OTHERS_PLATFORM');
		data.append('idVisitor', localStorage.getItem('idVisitor'));
		data.append('idCustomer',  localStorage.getItem('authorizedIdCustomer'));
		data.append('idRestaurant', `${this.props.restaurantId}`);
		data.append('idRestaurantBranch', `${this.props.branchId}`);
		
		if(masterId!=null && masterId>1) {
			data.append('idRefUwItemMaster', masterId);
		}
		if(itemId!=null && itemId>1) {
			data.append('idItem', itemId);
		}
		if(compositionId!=null && compositionId>1) {
			data.append('idComposition', compositionId);
		}

		data.append('offsetRows','0');
		data.append('limitRows','99999');
		data.append('tokenSession', localStorage.getItem('tokenSession'));
		data.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
		data.append('authorizedLogin', localStorage.getItem('authorizedLogin')); 
		
		await axios.post('https://www.uweat.com/process.do', data)
		.then((response) => {
			// The API call was successful!
			if (response.status === 200) {
				const res = Object.values(response.data);
				return new Promise((resolve, reject) => {
					resolve(res[0]);
				})
			}
		})
		.then((itemObj) => {
			this.setState({ items: this.state.items.concat(itemObj) })
			return this.state.items
		})
		.catch(function (error) {
			console.error('ERROR',error);
	 });
	}

	async getMediaVal(idMedia) {
    // const data = connectAPI('findPicture', 'MediaRequestControllerImpl', 'media/findPicture');
		const data = new FormData();
		data.append('PHEADACHEP_INPUT_PAGE','/PHEADACHEP/view/medium/500-error-page.php5');
		data.append('PHEADACHEP_ACTION_NAME','findPicture');
		data.append('PHEADACHEP_TYPE_DEVICE','medium');
		data.append('PHEADACHEP_LANG_CODE','FR_FRANCE');
		data.append('PHEADACHEP_CONTROLLER_NAME','MediaRequestControllerImpl');
		data.append('targetCall','uweatrest');
		data.append('callURL','http://www.uweat.com/media/findPicture');
		data.append('remoteAddr','127.0.0.1');
		data.append('sessionID','reading_picture_services_session');
		data.append('lang','FR_FRANCE');
		data.append('userCallerType','CallerTType.UWEAT_OTHERS_PLATFORM');
		data.append('idVisitor', localStorage.getItem('idVisitor'));
		data.append('idMedia',idMedia);
		data.append('callMethod','post');
		data.append('tokenSession', localStorage.getItem('tokenSession'));
		data.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
		data.append('authorizedLogin', localStorage.getItem('authorizedLogin')); 
    
    axios.post('https://www.uweat.com/process.do', data)
    .then((response) => {
      // The API call was successful!
      if (response.status === 200) {
        const res = Object.values(response.data);
        console.log('response',res);
        return new Promise((resolve, reject) => {
          resolve(res[0]);
        })
      }
    })
    .catch(function (error) {
      console.error('ERROR',error);
    });
  };


	render() {
		return (
			<TestStyle>
				{
					this.state.items.map((value, key) => {
						return (
							<Item key={key} iteminfo={value} picValue={this.state.picMediaVal} addItemToCart={this.props.addItemToCart} />
						)
					})
				}
			</TestStyle>
		)
	}
}

export default MenuItems;

