import React from 'react';
import styled from 'styled-components';

const SingleItemModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 36px;

  input {
    height: 85px;
    font-size: 36px;
    margin: 0;
    padding: 0;
    width: 80px;
    text-align: center;
  }

  button {
    width: 225px;
    height: 60px;
    font-size: 30px;
  }
`;

const ItemWrapperStyle = styled.div`
  flex-direction: row;
  display: flex;
  margin: 15px 0 20px 0;

  button {
    width: 77px;
    height: 82px;
    font-size: 36px;
  }

  p {
    height: 80px;
    width: 80px;
    text-align: center;
    padding-top: 14px;
  }
`;

class SingleItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size,
      price: props.price,
      quantity: 0
    }
  }

  handleSingleItem = () => {
    this.props.handleItemOnClick(this.state.size, this.state.price, this.state.quantity);
  }

  increaseValue = () => {
    this.setState({
      quantity: this.state.quantity + 1 
    });
  }

  decreaseValue = () => {
    this.setState(prevState => {
      if(prevState.quantity > 0) {
        return {
          quantity: prevState.quantity - 1
        }
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <SingleItemModalStyle>
        <p>Size - {this.props.size}</p>
        <p>Price - ${this.props.price}</p>
        <ItemWrapperStyle>
          <button onClick={this.decreaseValue}>-</button>
          <p>{this.state.quantity}</p>
          <button onClick={this.increaseValue}>+</button>
        </ItemWrapperStyle>
        <button onClick={this.handleSingleItem}>Add to Cart</button>
      </SingleItemModalStyle>
    )
  }
}

export default SingleItemModal;