import React from 'react';
import styled from 'styled-components';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const WrapperStyle = styled.div`
  border-bottom: 2px solid #707070;
  margin: 10px 20px;
  padding: 16px;
  margin-bottom: 60px;

  div.wrapper {
    display: flex;
    text-decoration: none;

    .img-restaurant {
      span {
        border-radius: 20px;
      }
    }

    .info-restaurant {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      padding: 30px;
      position: relative;

      h1 {
        margin-bottom: 20px;
      }

      p {
        font-size: 40px;

        &:last-child {
          margin-top: 75px;
          margin-left: 70%;
        }
      }
    }
  }
`;

const RestaurantSkeleton = () => {
  return (
    <SkeletonTheme color="#d2d2d2" highlightColor="#c1c1c1">

        <ul>
          {
            Array(6).fill().map((item, index) => (
              <WrapperStyle key={index}>
                <div className="wrapper">
                  <span className="img-restaurant"> 
                    <Skeleton height={300} width={300} />
                  </span>
                  <span className="info-restaurant">
                    <h1><Skeleton /></h1>
                    <p><Skeleton /></p>
                    <p><Skeleton /></p>
                  </span>
                </div>
              </WrapperStyle>
            ))
          }
        </ul>

    </SkeletonTheme>
  )
}

export default RestaurantSkeleton;