import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import takeaway from '../components/images/take-away.svg';
import fooddelivery from '../components/images/food-delivery.svg';

const ChoiceStyle = styled.div`
  position: relative;
  margin: 305px 50px 0 50px;

  .mode-style {
    background-color: #fcb300;
    width: 400px;
    display: flex;
    padding: 60px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 55%;
    font-weight: bold;
    line-height: 50px;
    margin-top: 720px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      left: 0;
      position: absolute;
    }

    &:last-child {
      right: 0;
      position: absolute;
    }

    span {
      text-align: center;
      margin: 0 auto;
      color: #494949;
    }

    img {
      margin: 0 auto;
    }
  }
`


class TypeOrder extends React.Component {
  render() {
    return (
      <>
        <ChoiceStyle>
          <Link className="mode-style" to="/restaurants">
            <img className="takeaway" src={takeaway} alt="take away" width="200" height="300" />
            <span>Take away</span>
          </Link>
          {/* <Link className="mode-style" to="/restaurantsTEST"><span>Takeout</span></Link> */}
          <Link className="mode-style" to="test">
            <img className="fooddelivery" src={fooddelivery} alt="food delivery" width="200" height="300" />
            <span>Delivery</span>
          </Link>
        </ChoiceStyle>
      </>
    ) 
  }
}

export default TypeOrder;