import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Ads from './Ads';

const MainStyle = styled.div`
  display: flex;
  flex-direction: column;
`

const HomeStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;

  .main-button {
    width: 100%;
    display: flex;
    padding: 60px 0 45px 0;
    text-align: center;
    text-decoration: none;
    flex-direction: column;
    justify-content: space-around;
    font-size: 55%;
    color: #666363;
    font-weight: bold;
    line-height: 50px;

    span:first-child {
      font-size: 76%;
    }

    span:last-child {
      font-size: 130%;
    }
  }
`;

const LangStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    font-size: 32px;
    height: 80px;
    width: 220px;
    margin: 0 40px;
  }
  
`;
const VersionStyle = styled.div`
display: flex;
flex-direction: row;
justify-content: center;

span {
  font-size: 12px;
  height: 40px;
  width: 300px;
  margin: 40px 40px;
}

`;

class Home extends React.Component {
  render() {

    return (
        <MainStyle>

          <Ads />          

          <HomeStyle>
            <Link className="main-button" to="/typesession"><span>TOUCHER POUR COMMENCER</span><span>TOUCH TO START</span></Link>
            <LangStyle>
              <button>English</button>
              <button>Francais</button>
            </LangStyle>
          </HomeStyle>
          
          <VersionStyle><span>version : 20200724-12:15</span></VersionStyle>

        </MainStyle>
	  );
  }
}


export default Home;