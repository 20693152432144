import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ItemsModal from './ItemsModal';

const ItemStyle = styled.div`
  width: 255px;
  font-size: 50px;
  margin: 10px 20px;

  button {
    border: none;

    img {
      margin-bottom: 10px;
      width: 255px;
      height: auto;
    }

    span {
      font-size: 28px;
    }
  }
`;

if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);

const Item = (props) => {
  const [modal, setModal] = useState(false);
  const [imageVal, setImageVal] = useState('');
  const handleShowMessageClick = () => setModal(true);
  const handleCloseModal = () => setModal(false);

  useEffect(() => {
    getMediaVal(props.iteminfo.idPicMedia);
  },[props.iteminfo.idPicMedia])


	const getMediaVal = async (idMedia) => {
    // const data = connectAPI('findPicture', 'MediaRequestControllerImpl', 'media/findPicture');
		const data = new FormData();
		data.append('PHEADACHEP_INPUT_PAGE','/PHEADACHEP/view/medium/500-error-page.php5');
		data.append('PHEADACHEP_ACTION_NAME','findPicture');
		data.append('PHEADACHEP_TYPE_DEVICE','medium');
		data.append('PHEADACHEP_LANG_CODE','FR_FRANCE');
		data.append('PHEADACHEP_CONTROLLER_NAME','MediaRequestControllerImpl');
		data.append('targetCall','uweatrest');
		data.append('callURL','http://www.uweat.com/media/findPicture');
		data.append('remoteAddr','127.0.0.1');
		data.append('sessionID','reading_picture_services_session');
		data.append('lang','FR_FRANCE');
		data.append('userCallerType','CallerTType.UWEAT_OTHERS_PLATFORM');
		data.append('idVisitor', localStorage.getItem('idVisitor'));
		data.append('idMedia',idMedia);
		data.append('callMethod','post');
		data.append('tokenSession', localStorage.getItem('tokenSession'));
		data.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
		data.append('authorizedLogin', localStorage.getItem('authorizedLogin')); 
    
    axios.post('https://www.uweat.com/process.do', data)
    .then((response) => {
      // The API call was successful!
      if (response.status === 200) {
        const res = Object.values(response.data);
        return new Promise((resolve, reject) => {
          resolve(res[0]);
        })
      }
    })
    .then((itemPicVal) => {
			setImageVal(itemPicVal.picMediaValue);
    })
    .catch(function (error) {
      console.error('ERROR',error);
    });
  };  

  return (
    <ItemStyle>
      <button onClick={handleShowMessageClick} >
        <img src={`data:image/png;base64,${imageVal}`} alt={props.iteminfo.shortTranslationValueFrFrance} />
        <span>{props.iteminfo.shortTranslationValueFrFrance}</span>
      </button>
      {modal ? (
        <ItemsModal onClose={handleCloseModal} itemInfo={props.iteminfo} addItemToCart={props.addItemToCart}>
          {props.iteminfo.shortTranslationValueFrFrance}
        </ItemsModal>
      ) : null}
    </ItemStyle>
  )
}

export default Item;