import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import MenuItems from './MenuItems';
import Cart from './Cart';
import { connectAPI } from '../helpers';

const MenusStyle = styled.div`
  margin: 305px 50px 0 50px;
  text-align: center;
`;

const MenuWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

const MenuTypeStyle = styled.div`
  height: 1120px;
  overflow-y: scroll;

  ul {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    
    
    li {
      button {
        border: 2px solid #2a2a2a;
        font-size: 38px;
        border-radius: 5px;
        margin: 10px;
        padding: 20px;
        width: 200px;
        height: 180px;
      }
    }
  }
`;

const ItemsStyle = styled.div`
  /* border: 1px solid grey; */
  margin-left: 20px;
  margin-top: 40px;
`;

if (!Object.entries)
	Object.entries = function( obj ){
	      var ownProps = Object.keys( obj ),
	         i = ownProps.length,
	         resArray = new Array(i); // preallocate the Array

	      while (i--)
	         resArray[i] = [ownProps[i], obj[ownProps[i]]];
	      return resArray;
	   };
	   
	   
class RestaurantMenus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      menuItems: {},
      displayItems: false,
      menuId: 0,
      order: {}
    };
  }

  componentDidMount() {
    this.getAllMenus();
  }

  async getAllMenus() {
    // Connect to API to get list of menus in a restaurant
    const formData = connectAPI('findRestaurantMenu', 'RestaurantControllerImpl', 'restaurant/findRestaurantMenu');
    formData.append('remoteAddr', localStorage.getItem('visitorIpAddr'));
    formData.append('sessionID', localStorage.getItem('visitorSession'));
    formData.append('previousExternalID','XXX');
    formData.append('userCallerType', 'CallerTType.UWEAT_OTHERS_PLATFORM');
    formData.append('idVisitor', localStorage.getItem('idVisitor'));
    formData.append('idRestaurant', `${this.props.match.params.id}`);
    formData.append('idRestaurantBranch', `${this.props.match.params.idbranch}`);
    formData.append('idCustomer',  localStorage.getItem('authorizedIdCustomer'));
    formData.append('offsetRows','0');
    formData.append('limitRows', '99999');
    formData.append('tokenSession', localStorage.getItem('tokenSession'));
    formData.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
    formData.append('authorizedLogin', localStorage.getItem('authorizedLogin')); 

    await axios.post('https://www.uweat.com/process.do', formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      })
      .then((data) => {
        this.setState({ menus: data });
      })
      .catch(function (error) {
        	console.error('ERROR',error);
       });

  }

  getAllMenuItems(menus, menuId) {
    return new Promise((resolve, reject) => {
      this.getMenuItem(menuId)
    })
  }

  async getMenuItems(idRestaurantMenu) {
    console.log('getMenuItems started')

    // Connect to API to get the list of items in a menu
    const data = connectAPI('findRestaurantMenuItems', 'RestaurantControllerImpl', 'restaurant/findRestaurantMenuItems');
    data.append('remoteAddr', localStorage.getItem('visitorIpAddr'));
    data.append('sessionID', localStorage.getItem('visitorSession'));
    data.append('previousExternalID','XXX');
    data.append('userCallerType', 'CallerTType.UWEAT_OTHERS_PLATFORM');
    data.append('idVisitor', localStorage.getItem('idVisitor'));
    data.append('idCustomer',  localStorage.getItem('authorizedIdCustomer'));
    data.append('idRestaurant', `${this.props.match.params.id}`);
    data.append('idRestaurantBranch', `${this.props.match.params.idbranch}`);
    data.append('idRestaurantMenu', idRestaurantMenu);
    data.append('offsetRows', '0');
    data.append('limitRows', '99999');
    data.append('tokenSession', localStorage.getItem('tokenSession'));
    data.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
    data.append('authorizedLogin', localStorage.getItem('authorizedLogin'));     
    
    await axios.post('https://www.uweat.com/process.do', data)
      .then((response) => {
        // The API call was successful!
        console.log('we got the response', response.data);
        if (response.status === 200) {
          const dataRes = Object.entries(response.data);
          console.log('we got status 200');
          // 1. Take a copy of the existing state
          // const menuItems = { ...this.state.menuItems };
          const menuItems = {};
          menuItems[idRestaurantMenu] = {};
          for (const [item, value] of dataRes) {
            const itemObj = Object.entries(value);

            var infoObj = {};
            for (const [key, info] of itemObj) {
              if (key === 'idItemCustomized') { infoObj.idItemCustomized = info; }
              else if (key === 'idRefUwItemMaster') { infoObj.idRefUwItemMaster = info; }
              else if (key === 'idComposition') { infoObj.idComposition = info; }
            }
            // 2. Add our new Menu item to menu items
            menuItems[idRestaurantMenu][item] = infoObj;
          }
          console.log('We got the menuItems', menuItems)
          return menuItems;
        }
      })
      .then((menuItems) => {
        this.setState({
          menuItems,
          displayItems: true,
          menuId: idRestaurantMenu
        });
      })
      .catch(function (error) {
        	console.error('ERROR',error);
       });

  }

  displayMenuItems(menuId) {
    this.setState({
      displayItems: false
    });
    this.getMenuItems(menuId);
  } 

  cancelOrder = () => {
    this.setState({ order: {}, total: 0 })
  }

  addItemToCart = async (itemId, itemInfo) => {
    // 1. Take a copy of state
    const order = { ...this.state.order };
    // 2. Either add to the order or update the number in our order
    order[itemId] = {
      name: itemInfo.name,
      price: itemInfo.price,
      size: itemInfo.size,
      quantity: itemInfo.quantity
    };
    // 3. Call setState to update our state object 
    this.setState({ order });
  }

  render() {
    const { menus, displayItems, menuId, menuItems } = this.state;

    return (
      <>
        <MenusStyle>
          <h2>{this.props.match.params.name}</h2>
          <MenuWrapperStyle>
            <MenuTypeStyle>
              <ul>
                {
                  Object.entries(menus).map(([key, value]) => {
                    return (
                      <li key={key}>
                        <button onClick={() => this.displayMenuItems(value.idRestaurantMenu)}>
                          {value.menuLabelKeyTranslationValue}
                        </button>
                      </li>
                    )
                  })
                }
              </ul>
            </MenuTypeStyle>
            <ItemsStyle>
              {
                displayItems ? 
                <MenuItems 
                  menus={menuItems[menuId]} 
                  menuId={menuId} 
                  addItemToCart={this.addItemToCart}
                  branchId={this.props.match.params.idbranch} 
                  restaurantId={this.props.match.params.id} /> : 
                null
              }
            </ItemsStyle>
          </MenuWrapperStyle>
        </MenusStyle>
        <Cart order={this.state.order} cancelOrder={this.cancelOrder} total={this.state.total}/>
      </>
    )
  }
}

export default RestaurantMenus;