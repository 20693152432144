import React from 'react';
import styled from 'styled-components';

const AdsStyle = styled.div`
  padding: 0 50px;
  margin-top: 305px;

  .wrap {
    border: 1px solid black;
    width: 100%;
    height: 800px;
    
    display: inline-flex;
    margin: 80px auto;
    flex-direction: column;
    justify-content: space-around;

    p {
      text-align: center;
    }
  }
`

const Ads = () => {
  return (
    <AdsStyle>
      <div className="wrap">
        <p>Advertising Partners</p>
      </div>
    </AdsStyle>
  )
}

export default Ads;