import React from 'react';
import SingleItemModal from './SingleItemModal';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const ItemModalWrapperStyle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
`;

const ItemModalInnerStyle = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  display: inline-block;
  min-height: 600px;
  margin: 1rem;
  position: relative;
  min-width: 600px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  button.close-btn {
    height: 80px;
    width: 220px;
    font-size: 42px;
  }
`;

const SizePriceStyle = styled.div`
  padding: 22px;
  margin-bottom: 40px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 20px

  p {
    font-size: 36px;
    line-height: 50px;
  }

`;

const InnerItemStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

if (!Object.entries)
	Object.entries = function( obj ){
	      var ownProps = Object.keys( obj ),
	         i = ownProps.length,
	         resArray = new Array(i); // preallocate the Array

	      while (i--)
	         resArray[i] = [ownProps[i], obj[ownProps[i]]];
	      return resArray;
	   };

const ItemsModal = (props) => {

  const arrItemInfo = Object.entries(props.itemInfo);
  const arrPrices = arrItemInfo.filter(([key, value]) => { return key.search('UnitRegularPrice') > 0 })
  const selectedIdItem = arrItemInfo.filter(([key, value]) => { return key.search('idItem') === 0 });
  const selectedIdComposition = arrItemInfo.filter(([key, value]) => { return key.search('idComposition') === 0 });
  const selectedIdItemMaster = arrItemInfo.filter(([key, value]) => { return key.search('idRefUwItemMaster ') === 0 });


  const handleItemOnClick = (size, price, quantity) => {
    const keyIdItem = selectedIdItem.length > 0 ? selectedIdItem[0][1] : null;
    const keyIdComposition = selectedIdComposition.length > 0 ? selectedIdComposition[0][1] : null;
    const keyIdItemMaster = selectedIdItemMaster.length > 0 ? selectedIdItemMaster[0][1] : null;
    props.addItemToCart(`${keyIdItem}-${keyIdComposition}-${keyIdItemMaster}-${size}`, {name: props.children, price, size, quantity})
  }

  return ReactDOM.createPortal(
    <ItemModalWrapperStyle>
      <ItemModalInnerStyle>
        {props.children}
        <hr />
        <InnerItemStyle>
        {
          arrPrices.map(([key, value]) => {
            let key1 = key.substring(0, key.search('UnitRegularPrice'));
            let key2 = key1.substring(('reItmCust').length, key.length);
            return (
              <SizePriceStyle key={key}>
                <SingleItemModal size={key2} price={value} handleItemOnClick={handleItemOnClick} />
              </SizePriceStyle>
            )
          })
        }
        </InnerItemStyle>
        <button className="close-btn" onClick={props.onClose}>Done</button>
      </ItemModalInnerStyle>
    </ItemModalWrapperStyle>,
    document.getElementById('item-modal-root')
  )
};

export default ItemsModal;