import React from 'react';
import Restaurant from './Restaurant';
import styled from 'styled-components';
import { connectAPI } from '../helpers';
import axios from 'axios';
import RestaurantSkeleton from './RestaurantsSkeleton';

const RestaurantsStyle = styled.div`
  .restaurant-list {
    margin: 305px 50px 180px 50px;
    text-align: center;

    button {
      font-size: 58px;
      text-align: center;
      border: 1px solid purple;
      padding: 30px 40px;
    }

    ul {
      margin-top: 30px;
      flex-grow:2;
    }
  }
`;

if (!Object.entries)
	Object.entries = function( obj ){
	      var ownProps = Object.keys( obj ),
	         i = ownProps.length,
	         resArray = new Array(i); // preallocate the Array

	      while (i--)
	         resArray[i] = [ownProps[i], obj[ownProps[i]]];
	      return resArray;
	   };

class Restaurants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      restaurants: [], 
      error: ''
    }
  }

  componentDidMount() {
	  console.error('-- log trace -- componentDidMount');
    this.fetchTraceVisitor();
  }

  async fetchTraceVisitor() {
	  console.error('-- log trace -- async fetchTraceVisitor');
    this.setState({ loading: !this.state.loading });
    const formData = connectAPI('traceVisitor', 'UserRequestControllerImpl', 'user/traceVisitor')
    formData.append('sessionID','kiosk_session')
    formData.append('userCallerType','CallerTType.UWEAT_ANDROID')

    axios.post('https://www.uweat.com/process.do', formData)
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem('idVisitor', response.data[0].idVisitor);
          localStorage.setItem('visitorSession', response.data[0].visitorSession);
          localStorage.setItem('visitorIpAddr', response.data[0].visitorIpAddr);
          this.fetchOpenSessions();
        }
        else {
          console.error('ERROR-traceVisitor', response.status);
        }
      })
      .catch(error => {
        console.error('ERROR-traceVisitor',error.message);
      })
  }

  async fetchOpenSessions() {
    const formData = connectAPI('openSession', 'UserRequestControllerImpl', 'user/openSession');
    formData.append('sessionID', localStorage.getItem('visitorSession'));
    formData.append('userCallerType','CallerTType.UWEAT_ANDROID');
    formData.append('remoteAddr', localStorage.getItem('visitorIpAddr'));
    formData.append('idVisitor', localStorage.getItem('idVisitor'));
    formData.append('login','georgeclooney');
    formData.append('password','20G20C#brainupai');
    formData.append('previousExternalID','1');

    axios.post('https://www.uweat.com/process.do', formData)
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem('idCustomer', response.data[0].idCustomer);
          localStorage.setItem('idTokenSession', response.data[0].idTokenSession);
          localStorage.setItem('login', response.data[0].login);
          localStorage.setItem('password', response.data[0].password);
          localStorage.setItem('tokenSession', response.data[0].tokenSession);
          localStorage.setItem('authorizedIdCustomer', response.data[0].idCustomer);
          localStorage.setItem('authorizedLogin', response.data[0].login);
          this.fetchRestaurants();
        } else {
          console.error('ERROR-OpenSession', response.status);
        }
      })
      .catch(error => {
        console.error('ERROR-OpenSession',error.message);
      })
  }

  async fetchRestaurants() {
    const data = connectAPI('findFoodCourtRestaurantBranch', 'RestaurantControllerImpl', 'restaurant/findFoodCourtRestaurantBranch');
    data.append('sessionID', localStorage.getItem('visitorSession'));
    data.append('userCallerType', 'CallerTType.UWEAT_OTHERS_PLATFORM');
    data.append('remoteAddr', localStorage.getItem('visitorIpAddr'));
    data.append('previousExternalID', '1');
    data.append('idVisitor', localStorage.getItem('idVisitor'));
    data.append('idCustomer', localStorage.getItem('authorizedIdCustomer'));
    data.append('withImage', 'true');
    data.append('offsetRows', '0');
    data.append('limitRows', '9999999');
    data.append('tokenSession', localStorage.getItem('tokenSession'));
    data.append('authorizedIdCustomer', localStorage.getItem('authorizedIdCustomer'));
    data.append('authorizedLogin', localStorage.getItem('authorizedLogin'));

    axios.post('https://www.uweat.com/process.do', data)
      .then(response => {
        if (response.status === 200) {
          const restaurants = response.data
          this.setState({ restaurants, loading: !this.state.loading });
        }
        else {
          console.error('ERROR-findFoodCourtRestaurantBranch', response.status);
        }
      })
      .catch(error => {
        console.error('ERROR-fetchRestaurants',error.message);
      })
  }
  

  render() {
    return (
      <RestaurantsStyle>
        <div className="restaurant-list">
          <h2>Choose a restaurant</h2>
          { this.state.loading ? <RestaurantSkeleton /> :
            <ul>
              {
                Object.entries(this.state.restaurants).map(([key, value]) => {
                  return <Restaurant key={key} info={value} />
                })
              }
            </ul>  
          }
        </div>
      </RestaurantsStyle>
    )
  }
}

export default Restaurants;