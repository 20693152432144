import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import TypeSession from './TypeSession';
import TypeOrder from './TypeOrder';
import Restaurants from './Restaurants';
import RestaurantMenus from './RestaurantMenus';
import Cart from './Cart';
import styled, { createGlobalStyle } from 'styled-components'; 
import reset from 'styled-reset';
import logo from '../components/images/KioskGourmetLogo.png';

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Quicksand', sans-serif;
    text-rendering: optimizeLegibility;
    background-color: #f0f0f0;
    font-size: 80px;
  }
`;

const WrapperStyle = styled.div`
  .logo-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #f0f0f0;
    z-index: 1;

    img.logo {
      display: flex;
      margin: 90px auto;
    }
  }
`;

const Router = (props) => {

  return (
    <WrapperStyle>
    <GlobalStyle />
      <BrowserRouter>
        <div>
          <div className="logo-wrap">
            <img className="logo" src={logo} alt="logo" width="766" height="125" />
          </div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/typesession" component={TypeSession} />
            <Route path="/typeorder" component={TypeOrder} />
            <Route path="/restaurants" component={Restaurants} />
            <Route path="/restaurant/:id/branch/:idbranch/name/:name" component={RestaurantMenus} />
            <Route path="/cart" component={Cart} />
          </Switch>
        </div>
      </BrowserRouter>
  </WrapperStyle>
  )
}

export default Router;