import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const ChoiceStyle = styled.div`
  position: relative;
  margin: 305px 50px 0 50px;

  .mode-style {
    background-color: #fcb300;
    width: 400px;
    padding: 60px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 55%;
    font-weight: bold;
    line-height: 50px;
    margin-top: 860px;
    border-radius: 8px;

    &:first-child {
      left: 0;
      position: absolute;
      padding: 50px 20px;
    }

    &:last-child {
      right: 0;
      position: absolute;
      padding: 75px 30px;
    }

    span {
      text-align: center;
      margin: 0 auto;
      color: #494949;
    }
  }
`


class TypeSession extends React.Component {
  render() {
    return (
      <>
        <ChoiceStyle>
          <Link className="mode-style" to="/typeorder"><span>Anonymous Order</span></Link>
          <Link className="mode-style" to="test"><span>Account Order</span></Link>
        </ChoiceStyle>
      </>
    ) 
  }
}

export default TypeSession;