import React from 'react';
import calcTotalOrder from '../lib/calcTotalOrder';
import calcTPS from '../lib/calcTPS';
import calcTVQ from '../lib/calcTVQ';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CartStyle = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopStyle = styled.div`
  background-color: #fcb300;
  padding: 12px;
  font-size: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #cdc9c9;
  color: #2a2a2a;

  p {
    font-weight: bold;
    line-height: initial;

    span {
      font-weight: normal;
    }
  }
`;

const BottomStyle = styled.div`
  border-top: 1px solid #cdc9c9;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;

  button {
    width: 350px;
    height: 100px;
    font-size: 36px;
    border-radius: 10px;
    
    &:first-child {
      margin: 0 0 30px 30px;
      background-color: #ff3333;
      color: #dae3ec;
    }

    &:last-child {
      margin: 0 30px 30px 0;
      background-color: #00b170;
      color: #f8f8f8;
    }
  }
`;

const ItemsListStyle = styled.div`
  flex-grow: 2;

  ul {
    height: 128px;
    overflow: scroll;

    li {
      font-size: 40px;
      padding: 10px 30px 0 30px;
    }
  }
`;

const BottomBackLinksStyle = styled.div`
  a {
    font-size: 32px;
    text-decoration: none;
    padding: 10px;
    color: #000;

    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }
`;

if (!Object.entries)
	Object.entries = function( obj ){
	      var ownProps = Object.keys( obj ),
	         i = ownProps.length,
	         resArray = new Array(i); // preallocate the Array

	      while (i--)
	         resArray[i] = [ownProps[i], obj[ownProps[i]]];
	      return resArray;
	   };
	   
	   
const Cart = (props) => {
  let total = calcTotalOrder(props.order)
  let tps = parseFloat(calcTPS(total));
  let tvq = parseFloat(calcTVQ(total));

  let totalOrder = (Math.round((total+tps+tvq) * 100) / 100).toFixed(2);

  return (

    <CartStyle>
      <TopStyle>
        <p>MY ORDER</p>
        <p>TPS 5% $<span>{tps}</span> TVQ 9.975% $<span>{tvq}</span></p>
        <p>Total $<span>{totalOrder}</span></p>
      </TopStyle>
      <ItemsListStyle>
        <ul>
          {
            Object.entries(props.order).map(([key,value]) => {
              console.log(value);
              return <li key={key}>{value.quantity} X {value.name} -{value.size} ${value.price}</li>
            })
          }
        </ul>
      </ItemsListStyle>
      <BottomStyle>
        <button onClick={props.cancelOrder}>Cancel order</button>
        <button>Done</button>
      </BottomStyle>
      <BottomBackLinksStyle>
        <Link className="backhome" to="/">&larr; Back Home</Link>
        <Link className="backrestaurants" to="/restaurants">&larr; Back Restaurants</Link>
      </BottomBackLinksStyle>
    </CartStyle>
  )
}

export default Cart;