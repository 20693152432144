export function connectAPI(actionname, controllername, callURL) {
  const formData = new FormData();
  formData.append('PHEADACHEP_INPUT_PAGE','/PHEADACHEP/view/medium/500-error-page.php5');
  formData.append('targetCall', 'uweatrest');
  formData.append('callMethod','post');
  formData.append('PHEADACHEP_LANG_CODE','FR_FRANCE');
  formData.append('lang', 'FR_FRANCE');
  formData.append('PHEADACHEP_ACTION_NAME',actionname);
  formData.append('PHEADACHEP_CONTROLLER_NAME',controllername);
  formData.append('callURL',`http://www.uweat.com/${callURL}`);
  formData.append('PHEADACHEP_TYPE_DEVICE', 'medium');
  return formData;
}