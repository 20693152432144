 if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);

export default function calcTotalPrice(cart) {
  return Object.values(cart).reduce((total, item) => {
    return total + (item.price * item.quantity)
  }, 0)
  // return cart.reduce((tally, cartItem) => {
  //   if (!cartItem.item) return tally;
  //   return tally + cartItem.quantity * cartItem.item.price;
  // }, 0);
}