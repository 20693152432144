import React from 'react';
import styled from 'styled-components';

const RestStyle = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 150%;
  font-weight: 900;
  border-bottom: 2px solid #707070;
  margin: 10px 20px;
  padding: 16px;
  margin-bottom: 60px;

  a.wrapper {
    display: flex;
    text-decoration: none;

    .img-restaurant {
      img {
        width: 300px;
        height: 300px;
        border-radius: 20px;
      }
    }

    .info-restaurant {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      padding: 30px;
      position: relative;

      h1 {
        font-size: 65px;
        line-height: 60px;
        color: #666363;
        text-decoration: none;
        margin-bottom: 20px;
      }

      p {
        font-size: 40px;
        font-weight: normal;
        color: #a19393;

        &:last-child {
          position: absolute;
          bottom: 30px;
          right: 30px;
          color: #e03447;
        }
      }
    }
  }
`

const Restaurant = ({ info }) => {

  return (
    
    <RestStyle>
 
	    <a className="wrapper" href={`/restaurant/${info.idRestaurant}/branch/${info.idRestaurantBranch}/name/${info.restaurantBranchName}`}>

        {
        	info.picMediaValue ? 
            <span className="img-restaurant"> 
              <img src={`data:image/png;base64,${info.picMediaValue}`} alt={info.restaurantBranchName} />
            </span> : 
            null
        }

        <span className="info-restaurant">
          <h1>{info.restaurantBranchName}</h1>
          <p>Description restaurant</p>
          <p>30 mins</p>
        </span>
      </a>

    </RestStyle>
  )
}

export default Restaurant;